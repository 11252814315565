<template>
  <div class="partner">
    <div class="WebMain">
      <div class="left">
        <div class="title">
          <img src="../../assets/img/partner/合作@2x.png" alt="" />
          <span class="p20">加入机构合作伙伴</span>
        </div>
        <p>
          LMAX交易所为您提供交易上的绝对优势LMAX交易所为金融平台量身订制的清算方案，让平台商直接取得机构流动性，优化外汇对冲。
        </p>
        <p>
          LMAXPrimeSM - 全套式、高成效的PB清算对接方案，可将LMAX交易所、Tier
          1一级银行和其他撮合流动性等多个报价源整合，集中结算。
        </p>
        <p>
          LMAXClearSM-
          专为寻求增加流动性的金融商设置，通过杠杆清算账户获得“无最后观望”的整合性流动清算方案。
        </p>
        <p>
          LMAXAccessSM-
          为已有清算机制的金融平台商提供“无最后观望”的LMAX整合流动性。
        </p>
        <p class="contact">
          请您通过我们客服微信或者客服邮箱（cs@maxiveinvest.com）与我们联系。
          <!-- 我们愿与机构和个人进行合作，为您量身定制属于您的清算方案。请您通过我们客服QQ、微信或者客服邮箱（cs@wanhaitouzi.com）与我们联系。 -->
        </p>
      </div>
      <div class="right">
        <div class="top">
          <div class="title">
            <img src="../../assets/img/partner/合作@2x.png" alt="" />
            <span class="p20">成为个人客户</span>
          </div>
          <p>
            个人客户可以通过我们万海在LMAX开通账户进行入金交易，如果您对在LMAX开户做交易有任何疑问可以随时联系我们。在LMAX交易所，散户、机构或者银行处于同等交易地位，交易完全透明，无后台，无加点，无交易员干预，无“最后的观望”，所有订单直接进入全球外汇市场。所有客户都可以设置价格，也可以获得价格。无论他们的资金大小，客户均都可以得到无歧视的真实市场价格。
          </p>
        </div>
        <div class="bottom">
          <div class="title">
            <img src="../../assets/img/partner/合作@2x.png" alt="" />
            <span class="p20">加入IB合作伙伴</span>
          </div>
          <p>
            在LMAX交易所，散户、机构或者银行处于同等交易地位，交易完全透明，无后台，无加点，无交易员干预，无“最后的观望”，所有订单直接进入全球外汇市场。所有客户都可以设置价格，也可以获得价格。无论他们的资金大小，客户均都可以得到无歧视的真实市场价格。我们愿与各代理商、个人IB竭诚合作，共同开拓市场。
          </p>
          <p class="contact">
            <!-- 请您通过我们客服QQ、客服电话(400
            9900175)或者客服邮箱(cs@wanhaitouzi.com)与我们联系。 -->
            请您通过我们客服微信或者客服邮箱（cs@maxiveinvest.com）与我们联系。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.partner {
  background: url("../../assets/img/partner/beijing@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 750px;
  .WebMain {
    display: flex;
    flex-wrap: wrap;
    padding: 100px 0;
    .contact {
      font-weight: 600;
    }
    p {
      font-size: 16px;
      color: #273133;
      line-height: 30px;
      margin-bottom: 9px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
    .title {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      img {
        width: 25px;
        margin-right: 15px;
      }
      span {
        font-weight: 600;
        color: #273133;
        line-height: 25px;
      }
    }
    .left {
      width: 450px;
      // height: 541px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
      border-radius: 6px;
      // opacity: 0.74;
      backdrop-filter: blur(25px);
      padding: 25px 30px;
     
    }
    .right {
      width: 730px;
      margin-left: 26px;
      .top,
      .bottom {
        box-sizing: border-box;
        padding: 25px 30px;
        width: 100%;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 6px;
        // opacity: 0.74;
        backdrop-filter: blur(25px);
      }

      .bottom {
        margin-top: 25px;
        // padding: 30px
        // height: 295px;
      }
     
    }
  }
}
@media screen and (max-width: 750px) {
  .partner .WebMain .right {
    margin-left: 0;
    margin-top: 20px;
    .top,
    .bottom {
      width: 100%;
    }
  }
}
</style>